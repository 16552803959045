// @flow
import React from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// components
import { FormInput } from '../../components/';
import { useSelector, useDispatch } from 'react-redux';

import { Loading } from 'react-loading-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUserProfile, updateProfile } from '../../API/RDPMS_API';
import Auth from '../../redux/auth/reducers';
import { AuthActionTypes } from '../../redux/auth/constants';
import { authApiResponseSuccess } from '../../redux/actions';

import { APICore, setAuthorization } from '../../helpers/api/apiCore';

const api = new APICore();

const PersonalInfo = ({ register, errors, control }) => {
    return (
        <>
            <h5 className="mb-4 text-uppercase">
                <i className="mdi mdi-account-circle me-1"></i> Personal Info
            </h5>
            <Row>
                <Col md={6}>
                    <FormInput
                        label="Full Name"
                        type="text"
                        name="name"
                        placeholder="Enter full name"
                        containerClass={'mb-3'}
                        register={register}
                        key="name"
                        errors={errors}
                        control={control}
                    />
                </Col>
                <Col md={6}>
                    <FormInput
                        label="Contact"
                        type="text"
                        name="contact"
                        placeholder="Enter contact"
                        containerClass={'mb-3'}
                        register={register}
                        key="contact"
                        errors={errors}
                        control={control}
                    />
                </Col>
                <Col md={6}>
                    <FormInput
                        label="Location Name"
                        type="text"
                        name="location"
                        placeholder="Enter location"
                        containerClass={'mb-3'}
                        register={register}
                        key="location"
                        errors={errors}
                        control={control}
                    />
                </Col>
                <Col md={6} className="mb-3">
                    <FormInput
                        label="Email"
                        type="text"
                        name="email"
                        placeholder="Enter email"
                        register={register}
                        key="email"
                        errors={errors}
                        control={control}
                        disabled
                    />
                </Col>
            </Row>

            <Row>
                <Col md={6} className="mb-3">
                    <span className="form-text text-muted">
                        <small>
                            If you want to change password please <Link to="/account/forget-password">click</Link> here.
                        </small>
                    </span>
                </Col>
            </Row>
        </>
    );
};

const Settings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { auth } = useSelector((state) => ({
        auth: state.Auth,
    }));

    const id = auth && auth.user && auth.user._id && auth.user._id;
    const role = auth && auth.user && auth.user.role && auth.user.role;
    const name = auth && auth.user && auth.user.name && auth.user.name;
    const contact = auth && auth.user && auth.user.contact && auth.user.contact;
    const email = auth && auth.user && auth.user.email && auth.user.email;
    const location = auth && auth.user && auth.user.location && auth.user.location;
    const password = auth && auth.user && auth.user.location && auth.user.password;

    const schema = yup
        .object()
        .shape({
            name: yup.string().required(t('Please enter name')),
            contact: yup.string().min(10).max(10).required(t('Please enter mobile number')),
            email: yup.string().required(t('Please enter email address')),
            location: yup.string().required(t('Please enter location')),
        })
        .required();

    /*
     * form methods
     */
    const methods = useForm({
        defaultValues: {
            name: name,
            role: role,
            contact: contact,
            email: email,
            location: location,
            password: password,
        },
        resolver: yupResolver(schema),
    });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = methods;

    const handleUserData = async (formData) => {
        formData.id = id;
        formData.role = role;
        formData.password = password;
        console.log(formData);
        Loading();
        let result = await updateProfile(formData);
        if (result) {
            let user = await getUserProfile(id);
            if (user) {
                dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
            }

            toast.success('Updated profile successfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error('Please retry again', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        Loading();
    };

    return (
        <>
            <ToastContainer />
            <form onSubmit={handleSubmit(handleUserData)}>
                <PersonalInfo register={register} errors={errors} control={control} />

                <div className="text-end">
                    <Button type="submit" className="btn btn-success mt-2">
                        <i className="mdi mdi-content-save"></i> Save
                    </Button>
                </div>
            </form>
        </>
    );
};

export default Settings;
