// @flow
import React from 'react';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap';

// components
import PageTitle from '../../components/PageTitle';

import UserBox from './UserBox';
import Settings from './Settings';

const index = () => {
    return (
        <>
            <Row>
                <Col>
                    <div className="page-title-box">
                        <h4 className="page-title">{'My Account'}</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={4} lg={5}>
                    {/* User information */}
                    <UserBox />
                </Col>
                <Col xl={8} lg={7}>
                    <Tab.Container defaultActiveKey="timeline">
                        <Card>
                            <Card.Body>
                                <Settings />
                            </Card.Body>
                        </Card>
                    </Tab.Container>
                </Col>
            </Row>
        </>
    );
};

export default index;
