import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import DetachedLayout from '../layouts/Detached';
import AddDcCircuit from '../pages/dashboard/Dashboard/dcCircuitOverview/AddDcCircuit';
import AddPointMachine from '../pages/dashboard/Dashboard/pointMachineOverview/AddPointMachine';
import AddBatteries from '../pages/dashboard/Dashboard/batteriesOverview/AddBatteries';
import AddIPS from '../pages/dashboard/Dashboard/ipsOverview/AddIPS';
import AddSignal from '../pages/dashboard/Dashboard/signalOverview/AddSignal';
import AddStation from '../pages/dashboard/Dashboard/station/AddStation';
import Alert from '../pages/alert/Alert';
import Profile from '../pages/profile';
import ReportsAlert from '../pages/reports/ReportsAlert';
import ReportsAlertGraph from '../pages/reports/ReportsAlertGraph';

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));
const DcCircuitOverview = React.lazy(() => import('../pages/dashboard/Dashboard/dcCircuitOverview'));
const DcCircuitOverviewPage1 = React.lazy(() => import('../pages/dashboard/Dashboard/dcCircuitOverview/dcCircuit'));
const signalOverview = React.lazy(() => import('../pages/dashboard/Dashboard/signalOverview'));
const signalOverviewPage = React.lazy(() => import('../pages/dashboard/Dashboard/signalOverview/Signal'));
const pointMachineOverview = React.lazy(() => import('../pages/dashboard/Dashboard/pointMachineOverview'));
const ipsOverview = React.lazy(() => import('../pages/dashboard/Dashboard/ipsOverview'));
const ipsOverviewPage = React.lazy(() => import('../pages/dashboard/Dashboard/ipsOverview/ips'));
const batteryChargerPage = React.lazy(() => import('../pages/dashboard/Dashboard/batteriesOverview/batteryCharger'));
const batteriesOverview = React.lazy(() => import('../pages/dashboard/Dashboard/batteriesOverview'));
const dataLoggerOverview = React.lazy(() => import('../pages/dashboard/Dashboard/dataLoggerOverview'));
const electronicInterlockingOverview = React.lazy(() =>
    import('../pages/dashboard/Dashboard/electronicInterlockingOverview')
);
const axialCountersOverview = React.lazy(() => import('../pages/dashboard/Dashboard/axialCountersOverview'));

//Details
const pointMachineDetails = React.lazy(() =>
    import('../pages/dashboard/Dashboard/pointMachineOverview/pointMachineDetails')
);

const loading = () => <div className=""></div>;

const LoadComponent = ({ component: Component }) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { auth } = useSelector((state) => ({
        auth: state.Auth,
    }));

    let authCred = '';
    authCred = auth && auth.user && auth.user.role;
    const getLayout = () => {
        let layoutCls = DetachedLayout;
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={authCred} component={Layout} />,
            children: [
                {
                    path: 'rdpms',
                    children: [
                        {
                            path: 'dashboard',
                            element:
                                authCred == 'admin' ? (
                                    <LoadComponent component={Dashboard} />
                                ) : authCred == 'customer' ? (
                                    <LoadComponent component={Dashboard} />
                                ) : (
                                    ''
                                ),
                        },
                        {
                            path: 'alert',
                            element: <LoadComponent component={Alert} />,
                        },
                        {
                            path: 'reportsalert',
                            element: <LoadComponent component={ReportsAlert} />,
                        },
                        {
                            path: 'reportsalertgraph',
                            element: <LoadComponent component={ReportsAlertGraph} />,
                        },
                        {
                            path: 'station',
                            element: <LoadComponent component={AddStation} />,
                        },
                        {
                            path: 'dccircuit',
                            element: <LoadComponent component={AddDcCircuit} />,
                        },
                        {
                            path: 'pointmachine',
                            element: <LoadComponent component={AddPointMachine} />,
                        },
                        {
                            path: 'batteries',
                            element: <LoadComponent component={AddBatteries} />,
                        },
                        {
                            path: 'ips',
                            element: <LoadComponent component={AddIPS} />,
                        },
                        {
                            path: 'signal',
                            element: <LoadComponent component={AddSignal} />,
                        },
                        {
                            path: 'dcCircuitOverview',
                            element: <LoadComponent component={DcCircuitOverview} />,
                        },
                        {
                            path: 'dcCircuitOverviewPage',
                            element: <LoadComponent component={DcCircuitOverviewPage1} />,
                        },
                        {
                            path: 'pointMachineOverview',
                            element: <LoadComponent component={pointMachineOverview} />,
                        },
                        {
                            path: 'signalOverview',
                            element: <LoadComponent component={signalOverview} />,
                        },
                        {
                            path: 'signalOverviewPage',
                            element: <LoadComponent component={signalOverviewPage} />,
                        },
                        {
                            path: 'ipsOverview',
                            element: <LoadComponent component={ipsOverview} />,
                        },
                        {
                            path: 'ipsOverviewPage',
                            element: <LoadComponent component={ipsOverviewPage} />,
                        },
                        {
                            path: 'batteryChargerPage',
                            element: <LoadComponent component={batteryChargerPage} />,
                        },
                        {
                            path: 'batteriesOverview',
                            element: <LoadComponent component={batteriesOverview} />,
                        },
                        {
                            path: 'dataLoggerOverview',
                            element: <LoadComponent component={dataLoggerOverview} />,
                        },
                        {
                            path: 'electronicInterlockingOverview',
                            element: <LoadComponent component={electronicInterlockingOverview} />,
                        },
                        {
                            path: 'axialCountersOverview',
                            element: <LoadComponent component={axialCountersOverview} />,
                        },
                        {
                            path: 'pointMachineDetails',
                            element: <LoadComponent component={pointMachineDetails} />,
                        },
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile} />,
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
