// @flow
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// images
import profileImg from '../../assets/images/users/avatar-1.jpg';
import { useSelector } from 'react-redux';

const UserBox = () => {
    const { auth } = useSelector((state) => ({
        auth: state.Auth,
    }));

    const role = auth && auth.user && auth.user.role && auth.user.role;
    const name = auth && auth.user && auth.user.name && auth.user.name;
    const contact = auth && auth.user && auth.user.contact && auth.user.contact;
    const email = auth && auth.user && auth.user.email && auth.user.email;
    const location = auth && auth.user && auth.user.location && auth.user.location;
    const stationName = auth && auth.user && auth.user.station && auth.user.station;

    return (
        <Card className="text-center">
            <Card.Body>
                <img src={profileImg} className="rounded-circle avatar-lg img-thumbnail" alt="" />
                <h4 className="mb-0 mt-2">{name}</h4>
                <p className="text-muted font-14 text-capitalize">{role}</p>
                {/* <button type="button" className="btn btn-success btn-sm mb-2">
                    Follow
                </button>{' '}
                <button type="button" className="btn btn-danger btn-sm mb-2">
                    Message
                </button> */}
                <div className="text-start mt-3">
                    {/* <h4 className="font-13 text-uppercase">About Me :</h4>
                    <p className="text-muted font-13 mb-3">
                        Hi I'm {role} {name}.
                    </p> */}
                    <p className="text-muted mb-2 font-13">
                        <strong>Full Name :</strong>
                        <span className="ms-2 text-capitalize">{name}</span>
                    </p>

                    <p className="text-muted mb-2 font-13">
                        <strong>Mobile :</strong>
                        <span className="ms-2">{contact}</span>
                    </p>

                    <p className="text-muted mb-2 font-13">
                        <strong>Email :</strong>
                        <span className="ms-2">{email}</span>
                    </p>

                    <p className="text-muted mb-1 font-13">
                        <strong>Location :</strong>
                        <span className="ms-2 text-capitalize">{location}</span>
                    </p>
                    <p className="text-muted mb-1 font-13">
                        <strong>Role :</strong>
                        <span className="ms-2 text-capitalize">{role}</span>
                    </p>
                    <p className="text-muted mb-1 font-13">
                        <strong>Station Name :</strong>
                        <span className="ms-2 text-capitalize">
                            {stationName.map((lbl, index) => {
                                return <>{lbl.name + ' , '}</>;
                            })}
                        </span>
                    </p>
                </div>
                {/* <ul className="social-list list-inline mt-3 mb-0">
                    <li className="list-inline-item">
                        <Link to="#" className="social-list-item border-primary text-primary">
                            <i className="mdi mdi-facebook"></i>
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        <Link to="#" className="social-list-item border-danger text-danger">
                            <i className="mdi mdi-google"></i>
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        <Link to="#" className="social-list-item border-info text-info">
                            <i className="mdi mdi-twitter"></i>
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        <Link to="#" className="social-list-item border-secondary text-secondary">
                            <i className="mdi mdi-github"></i>
                        </Link>
                    </li>
                </ul> */}
            </Card.Body>
        </Card>
    );
};

export default UserBox;
