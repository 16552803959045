// @flow
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Modal, Button, Badge, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { VerticalForm, FormInput } from '../../../../components';
// components
import Table from '../../../../components/Table';
import {
    addDCCircuitAPI,
    addStationAPI,
    deleteDCCircuitAPI,
    deleteStationAPI,
    editDCCircuitAPI,
    editStationAPI,
    getAllStationAPI,
    getUserProfile,
} from '../../../../API/RDPMS_API';
import moment from 'moment';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from 'react-loading-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthActionTypes } from '../../../../redux/auth/constants';
import { APICore, setAuthorization } from '../../../../helpers/api/apiCore';
import { authApiResponseSuccess } from '../../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
const api = new APICore();

const AddStation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [stationName, setStationName] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showEditModalData, setShowEditModalData] = useState([]);

    useEffect(() => {
        getAllStationData();
    }, []);

    const { auth } = useSelector((state) => ({
        auth: state.Auth,
    }));

    let userId = auth && auth.user && auth.user._id && auth.user._id;

    const getAllStationData = async (limits, pages) => {
        Loading();
        let limitNumber = limits ? limits : limit;
        let pagesNumber = pages ? pages : pageNumber;

        let request = {
            limit: limitNumber,
            page: pagesNumber,
            userId,
        };
        let response = await getAllStationAPI(request);

        if (response && response.data && response.data.length > 0) {
            let dataArray = await response.data.map((item) => {
                return {
                    id: item._id,
                    createdDate: moment(item.updatedAt).format('Do MMM, h:mm a'),
                    name: item.name,
                    stationMasterName: item.station_master,
                    city: item.city,
                    state: item.state,
                    action: (
                        <>
                            <Link to="#" className="action-icon" onClick={() => openEditModalWithHeaderClass(item)}>
                                {' '}
                                <i className="mdi mdi-pencil"></i>
                            </Link>
                            <Link to="#" className="action-icon" onClick={() => onDeleteStation(item._id)}>
                                {' '}
                                <i className="mdi mdi-delete"></i>
                            </Link>
                        </>
                    ),
                };
            });

            setPageNumber(response.currentPage);
            setPageCount(response.totalPages);
            setData(dataArray);
            setTotalCount(response.totalCount);
        }

        Loading();
    };

    //pagination
    const handlePageChange = async (value) => {
        setPageNumber(1);
        setLimit(value);
        await getAllStationData(value, 1);
    };

    const handlePageClick = async (value) => {
        setPageNumber(value);
        await getAllStationData(limit, value);
    };

    const columns = [
        {
            Header: t('Created Date'),
            accessor: 'createdDate',
            sort: true,
        },
        {
            Header: t('Station Name'),
            accessor: 'name',
            sort: true,
        },
        {
            Header: t('Station Master Name'),
            accessor: 'stationMasterName',
            sort: false,
        },
        {
            Header: t('City'),
            accessor: 'city',
            sort: false,
        },
        {
            Header: t('State'),
            accessor: 'state',
            sort: false,
        },
        {
            Header: t('Action'),
            accessor: 'action',
            sort: false,
        },
    ];
    const sizePerPageList = [
        {
            text: '5',
            value: 5,
        },
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
    ];

    const closeModalWithHeaderClass = () => {
        setShowModal(false);
    };
    const openModalWithHeaderClass = () => {
        setShowModal(true);
    };

    const closeEditModalWithHeaderClass = () => {
        setShowEditModal(false);
    };
    const openEditModalWithHeaderClass = (data) => {
        setShowEditModalData(data);
        setShowEditModal(true);
    };

    /*
     * form validation schema
     */
    const schemaResolver = yupResolver(
        yup.object().shape({
            name: yup.string().required(t('Please enter station name')),
            stationMaster: yup.string().required(t('Please select station master name')),
            city: yup.string().required(t('Please enter city name')),
            state: yup.string().required(t('Please enter state name')),
        })
    );

    /*
     * handle form submission
     */
    const onSubmit = async (formData) => {
        setLoader(true);
        formData.userId = userId;
        let result = await addStationAPI(formData);

        if (result) {
            let user = await getUserProfile(userId);
            if (user) {
                dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
            }
            getAllStationData();
            setShowModal(false);
            toast.success('Added new station successfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error('Please enter diffrent station', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setLoader(false);
    };

    const onEdit = async (formData) => {
        setLoader(true);
        formData.userId = userId;
        formData.id = showEditModalData._id;
        let result = await editStationAPI(formData);
        if (result) {
            let user = await getUserProfile(userId);
            if (user) {
                dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
            }
            getAllStationData();
            setShowEditModal(false);
            toast.success('Updated station successfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setLoader(false);
    };

    const onDeleteStation = async (id) => {
        Loading();
        if (window.confirm('Are you sure you wish to delete this dc circuit?')) {
            let result = await deleteStationAPI(id);

            if (result) {
                let user = await getUserProfile(userId);
                if (user) {
                    dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
                }
                getAllStationData();
                toast.success('Deleted station successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error('Deleted station unsuccessfully', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
        Loading();
    };

    return (
        <>
            <ToastContainer />
            <Row>
                <Col>
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <form className="d-flex">
                                <div className="input-group">
                                    <Button variant="primary" onClick={openModalWithHeaderClass}>
                                        ADD
                                    </Button>
                                    {/* <HyperDatepicker
                                        value={selectedDate}
                                        inputClass="form-control-light"
                                        onChange={(date) => {
                                            onDateChange(date);
                                        }}
                                    /> */}
                                </div>
                                <button className="btn btn-primary ms-2">
                                    <i className="mdi mdi-autorenew"></i>
                                </button>
                            </form>
                        </div>
                        <h4 className="page-title">{t('Station')}</h4>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Table
                                columns={columns}
                                data={data}
                                pageSize={limit}
                                pageCount={pageCount}
                                pageNumber={pageNumber}
                                totalCount={totalCount}
                                sizePerPageList={sizePerPageList}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                                onPageChange={handlePageChange}
                                onPageClick={handlePageClick}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} size="xs" onHide={closeModalWithHeaderClass}>
                <Modal.Header onHide={closeModalWithHeaderClass} closeButton className="modal-colored-header">
                    <h4 className="modal-title text-black">{t('ADD STATION')}</h4>
                </Modal.Header>

                <Modal.Body>
                    <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
                        <FormInput
                            label={t('Station Name')}
                            type="text"
                            name="name"
                            placeholder={t('Enter station name')}
                            containerClass={'mb-3'}
                        />

                        <FormInput
                            type="select"
                            label="Station Master Name"
                            name="stationMaster"
                            containerClass={'mb-3'}
                            key="className">
                            <option value="">{t('Select Station Master Name')}</option>
                            <option value="admin">Admin</option>
                            <option value="customer">Customer</option>
                        </FormInput>

                        <FormInput
                            label={t('City')}
                            type="text"
                            name="city"
                            placeholder={t('Enter city')}
                            containerClass={'mb-3'}
                        />

                        <FormInput
                            label={t('State')}
                            type="text"
                            name="state"
                            placeholder={t('Enter state')}
                            containerClass={'mb-3'}
                        />

                        <Modal.Footer>
                            <Button className="btn btn-primary mt-2" type="submit" disabled={loader ? true : false}>
                                {loader ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {t(' Loading')}
                                    </>
                                ) : (
                                    <>
                                        <i className="mdi mdi-content-save"></i>
                                        {t('Submit')}
                                    </>
                                )}
                            </Button>
                        </Modal.Footer>
                    </VerticalForm>
                </Modal.Body>
            </Modal>

            <Modal show={showEditModal} size="xs" onHide={closeEditModalWithHeaderClass}>
                <Modal.Header onHide={closeEditModalWithHeaderClass} closeButton className="modal-colored-header">
                    <h4 className="modal-title text-black">EDIT STATION</h4>
                </Modal.Header>

                <Modal.Body>
                    <VerticalForm onSubmit={onEdit} resolver={schemaResolver}>
                        <FormInput
                            label={t('Station Name')}
                            type="text"
                            name="name"
                            placeholder={t('Enter station name')}
                            containerClass={'mb-3'}
                            defaultValue={showEditModalData.name}
                        />

                        <FormInput
                            type="select"
                            label={t('Station Master Name')}
                            name="stationMaster"
                            containerClass={'mb-3'}
                            key="className">
                            <option value={showEditModalData.station_master}>{showEditModalData.station_master}</option>
                            {showEditModalData.station_master !== 'admin' ? <option value="admin">Admin</option> : ''}
                            {showEditModalData.station_master !== 'customer' ? (
                                <option value="customer">Customer</option>
                            ) : (
                                ''
                            )}
                        </FormInput>

                        <FormInput
                            label={t('City')}
                            type="text"
                            name="city"
                            placeholder={t('Enter city')}
                            containerClass={'mb-3'}
                            defaultValue={showEditModalData.city}
                        />

                        <FormInput
                            label={t('State')}
                            type="text"
                            name="state"
                            placeholder={t('Enter state')}
                            containerClass={'mb-3'}
                            defaultValue={showEditModalData.state}
                        />

                        <Modal.Footer>
                            <Button variant="primary" type="submit" disabled={loader ? true : false}>
                                {loader ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {t(' Loading')}
                                    </>
                                ) : (
                                    <>
                                        <i className="mdi mdi-login"></i>
                                        {t('Submit')}
                                    </>
                                )}
                            </Button>
                        </Modal.Footer>
                    </VerticalForm>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddStation;
