// @flow
import React from 'react';
import Chart from 'react-apexcharts';

const ChargerCurrentLineChart = ({ data }) => {
    const apexLineChartWithLables = {
        chart: {
            height: 364,
            type: 'line',
            dropShadow: {
                enabled: true,
                opacity: 0.1,
                blur: 7,
                left: -7,
                top: 7,
            },
            toolbar: {
                show: true,
            },
            parentHeightOffset: 0,
        },
        grid: {
            padding: {
                left: 0,
                right: 0,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [3],
            curve: 'straight',
        },
        zoom: {
            enabled: true,
        },
        // legend: {
        //     position: 'top',
        //     horizontalAlign: 'right',
        //     showForSingleSeries: true,
        // show: true,
        // },
        // markers: {
        //     size: [5, 7],
        // },
        colors: ['#727cf5', '#0acf97', '#fa5c7c', '#ffbc00'],
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'dd MMM yyyy',
            },
            tooltip: {
                enabled: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            type: 'value',
            forceNiceScale: true,
        },
    };

    const apexLineChartWithLablesData = [
        {
            name: 'Charger Current',
            data: data,
        },
    ];

    return (
        <Chart
            options={apexLineChartWithLables}
            series={apexLineChartWithLablesData}
            type="line"
            className="apex-charts mt-3"
            height={378}
        />
    );
};

export default ChargerCurrentLineChart;

// import ReactEChart from 'echarts-for-react';
// export default function ChargerCurrentLineChart({ color, data }) {
//     var colorPalette = [color];

//     const eChartsOption = {
//         tooltip: {
//             trigger: 'axis',
//         },
//         legend: {
//             right: 0,
//             data: ['Charger Current'],
//         },
//         xAxis: {
//             type: 'category',
//             boundaryGap: false,
//             min: 0,
//         },
//         yAxis: {
//             type: 'value',
//         },
//         dataZoom: [
//             {
//                 type: 'inside',
//                 xAxisIndex: 0,
//                 minSpan: 5,
//             },
//             {
//                 type: 'slider',
//                 xAxisIndex: 0,
//                 minSpan: 5,
//                 bottom: 7,
//             },
//         ],
//         series: [
//             {
//                 name: 'Charger Current',
//                 type: 'line',
//                 data: data,
//             },
//         ],
//         color: colorPalette,
//     };

//     return (
//         <div>
//             <ReactEChart option={eChartsOption} style={{ width: '100%' }} />
//         </div>
//     );
// }
