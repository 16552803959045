// @flow
import React, { useEffect, useState, useRef } from 'react';
import { Badge, Button, Card, Col, Form, Row } from 'react-bootstrap';
import HyperDatepicker from '../../components/Datepicker';
import { FormInput } from '../../components';
import { useSelector } from 'react-redux';
import { Loading } from 'react-loading-ui';
import { getAllStationAPI, getReportAPI, getUserStationAPI } from '../../API/RDPMS_API';
import { ToastContainer, toast } from 'react-toastify';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Table from '../../components/Table';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import reportImg from '../../assets/images/report.png';
const ReportsAlert = () => {
    const { t } = useTranslation();

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [selectedStation, setSelectedStation] = useState();
    const [selectedCategory, setSelectedCategory] = useState();

    const [station, setStation] = useState([]);

    const [dcCircuitData, setDcCircuitData] = useState([]);
    const [pointMachineData, setPointMachineData] = useState([]);
    const [signalData, setSignalData] = useState([]);
    const [batteriesData, setBatteriesData] = useState([]);
    const [ipsData, setIPSData] = useState([]);

    const { auth } = useSelector((state) => ({
        auth: state.Auth,
    }));

    console.log(auth.user._id, 'user');
    useEffect(() => {
        getStation(auth.user._id);
    }, []);
    const getStation = async (id) => {
        Loading();
        if (id) {
            let response = await getUserStationAPI(id);
            if (response && response.length > 0) {
                setStation(response);
            }
        }
        Loading();
    };
    /*
     * handle date change
     */
    const handleGetReport = async () => {
        Loading();
        setDcCircuitData([]);
        setPointMachineData([]);
        setSignalData([]);
        setBatteriesData([]);
        setIPSData([]);
        if (selectedStation && selectedCategory && selectedStartDate && selectedEndDate) {
            let request = {
                stationId: selectedStation,
                category: selectedCategory,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
            };

            let response = await getReportAPI(request);
            console.log(response, 'report response');
            if (response) {
                if (response.dcCircuit.length > 0) {
                    let dataArray = await response.dcCircuit.map((t) => {
                        return {
                            id: t._id,
                            trackNumber: t.track_number,
                            feedEndVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].feedEndVoltage === 1,
                                            'text-warning': t.severity_status[0].feedEndVoltage === 2,
                                            'text-danger': t.severity_status[0].feedEndVoltage === 3,
                                            '': t.severity_status[0].feedEndVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.feed_end_voltage}
                                </span>
                            ),
                            relayEndVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].relayEndVoltage === 1,
                                            'text-warning': t.severity_status[0].relayEndVoltage === 2,
                                            'text-danger': t.severity_status[0].relayEndVoltage === 3,
                                            '': t.severity_status[0].relayEndVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.relay_end_voltage}
                                </span>
                            ),
                            chargerCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].chargerCurrent === 1,
                                            'text-warning': t.severity_status[0].chargerCurrent === 2,
                                            'text-danger': t.severity_status[0].chargerCurrent === 3,
                                            '': t.severity_status[0].chargerCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.charger_current}
                                </span>
                            ),
                            tprStatus: (
                                <Badge
                                    bg=""
                                    className={classNames(
                                        'me-1',
                                        t.tpr_status == 'OFF' ? 'badge-danger-lighten' : 'badge-success-lighten'
                                    )}>
                                    {t.tpr_status == 'OFF' ? 'OFF' : 'ON'}
                                </Badge>
                            ),
                            chargerPotentialFreeContact: (
                                <Badge
                                    bg=""
                                    className={classNames(
                                        'me-1',
                                        t.charger_potential_free_contact == 'OFF'
                                            ? 'badge-danger-lighten'
                                            : 'badge-success-lighten'
                                    )}>
                                    {t.charger_potential_free_contact == 'OFF' ? 'OFF' : 'ON'}
                                </Badge>
                            ),

                            relayEndCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].relayEndCurrent === 1,
                                            'text-warning': t.severity_status[0].relayEndCurrent === 2,
                                            'text-danger': t.severity_status[0].relayEndCurrent === 3,
                                            '': t.severity_status[0].relayEndCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.relay_end_current}
                                </span>
                            ),

                            feedEndCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].feedEndCurrent === 1,
                                            'text-warning': t.severity_status[0].feedEndCurrent === 2,
                                            'text-danger': t.severity_status[0].feedEndCurrent === 3,
                                            '': t.severity_status[0].feedEndCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.feed_end_current}
                                </span>
                            ),
                        };
                    });

                    setDcCircuitData(dataArray);
                }
                if (response.pointMachine.length > 0) {
                    let dataArray = await response.pointMachine.map((t) => {
                        return {
                            id: t._id,
                            pointMachine: t.point_machine,
                            forwardOperatingCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].forwardOperatingCurrent === 1,
                                            'text-warning': t.severity_status[0].forwardOperatingCurrent === 2,
                                            'text-danger': t.severity_status[0].forwardOperatingCurrent === 3,
                                            '': t.severity_status[0].forwardOperatingCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.forward_operating_current}
                                </span>
                            ),
                            reverseOperatingCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].reverseOperatingCurrent === 1,
                                            'text-warning': t.severity_status[0].reverseOperatingCurrent === 2,
                                            'text-danger': t.severity_status[0].reverseOperatingCurrent === 3,
                                            '': t.severity_status[0].reverseOperatingCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.reverse_operating_current}
                                </span>
                            ),
                            forwardOperatingVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].forwardOperatingVoltage === 1,
                                            'text-warning': t.severity_status[0].forwardOperatingVoltage === 2,
                                            'text-danger': t.severity_status[0].forwardOperatingVoltage === 3,
                                            '': t.severity_status[0].forwardOperatingVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.forward_operating_voltage}
                                </span>
                            ),
                            reverseOperatingVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].reverseOperatingVoltage === 1,
                                            'text-warning': t.severity_status[0].reverseOperatingVoltage === 2,
                                            'text-danger': t.severity_status[0].reverseOperatingVoltage === 3,
                                            '': t.severity_status[0].reverseOperatingVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.reverse_operating_voltage}
                                </span>
                            ),

                            pointPositionVoltage: (
                                <Badge
                                    bg=""
                                    className={
                                        t.point_position_voltage == 'Forward'
                                            ? classNames('me-1', 'badge-success-lighten')
                                            : classNames('me-1', 'badge-danger-lighten')
                                    }>
                                    {t.point_position_voltage}
                                </Badge>
                            ),
                            vibration: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].vibration === 1,
                                            'text-warning': t.severity_status[0].vibration === 2,
                                            'text-danger': t.severity_status[0].vibration === 3,
                                            '': t.severity_status[0].vibration === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {'NA'}
                                </span>
                            ),
                            pointPositionRelay: (
                                <Badge
                                    bg=""
                                    className={
                                        t.point_position_relay == 'Forward'
                                            ? classNames('me-1', 'badge-success-lighten')
                                            : classNames('me-1', ' badge-danger-lighten')
                                    }>
                                    {t.point_position_relay}
                                </Badge>
                            ),
                        };
                    });
                    setPointMachineData(dataArray);
                }
                if (response.signal.length > 0) {
                    let dataArray = await response.signal.map((t) => {
                        return {
                            id: t._id,
                            signalNumber: t.signal_number,
                            redAcVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].redAcVoltage === 1,
                                            'text-warning': t.severity_status[0].redAcVoltage === 2,
                                            'text-danger': t.severity_status[0].redAcVoltage === 3,
                                            '': t.severity_status[0].redAcVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.red_ac_voltage}
                                </span>
                            ),
                            redAcCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].redAcCurrent === 1,
                                            'text-warning': t.severity_status[0].redAcCurrent === 2,
                                            'text-danger': t.severity_status[0].redAcCurrent === 3,
                                            '': t.severity_status[0].redAcCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.red_ac_current}
                                </span>
                            ),
                            greenAcVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].greenAcVoltage === 1,
                                            'text-warning': t.severity_status[0].greenAcVoltage === 2,
                                            'text-danger': t.severity_status[0].greenAcVoltage === 3,
                                            '': t.severity_status[0].greenAcVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.green_ac_voltage}
                                </span>
                            ),
                            greenAcCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].greenAcCurrent === 1,
                                            'text-warning': t.severity_status[0].greenAcCurrent === 2,
                                            'text-danger': t.severity_status[0].greenAcCurrent === 3,
                                            '': t.severity_status[0].greenAcCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.green_ac_current}
                                </span>
                            ),
                            yellowAcVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].yellowAcVoltage === 1,
                                            'text-warning': t.severity_status[0].yellowAcVoltage === 2,
                                            'text-danger': t.severity_status[0].yellowAcVoltage === 3,
                                            '': t.severity_status[0].yellowAcVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.yellow_ac_voltage}
                                </span>
                            ),
                            yellowAcCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].yellowAcCurrent === 1,
                                            'text-warning': t.severity_status[0].yellowAcCurrent === 2,
                                            'text-danger': t.severity_status[0].yellowAcCurrent === 3,
                                            '': t.severity_status[0].yellowAcCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.yellow_ac_current}
                                </span>
                            ),
                            otherAcVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].otherAcVoltage === 1,
                                            'text-warning': t.severity_status[0].otherAcVoltage === 2,
                                            'text-danger': t.severity_status[0].otherAcVoltage === 3,
                                            '': t.severity_status[0].otherAcVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.other_ac_voltage}
                                </span>
                            ),
                            otherAcCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].otherAcCurrent === 1,
                                            'text-warning': t.severity_status[0].otherAcCurrent === 2,
                                            'text-danger': t.severity_status[0].otherAcCurrent === 3,
                                            '': t.severity_status[0].otherAcCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.other_ac_current}
                                </span>
                            ),
                            status: (
                                <Badge
                                    bg=""
                                    className={
                                        t.health_status == 'RED'
                                            ? classNames('me-1', 'badge-danger-lighten')
                                            : t.health_status == 'GREEN'
                                            ? classNames('me-1', 'badge-success-lighten')
                                            : t.health_status == 'YELLOW'
                                            ? classNames('me-1', 'badge-warning-lighten')
                                            : t.health_status == 'OTHER'
                                            ? classNames('me-1', 'badge-warning-lighten')
                                            : ''
                                    }>
                                    {t.health_status}
                                </Badge>
                            ),
                        };
                    });

                    setSignalData(dataArray);
                }
                if (response.batteries.length > 0) {
                    let dataArray = await response.batteries.map((t) => {
                        return {
                            id: t._id,
                            batteryCharger: t.battery_charger,
                            chargerInputVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].chargerInputVoltage === 1,
                                            'text-warning': t.severity_status[0].chargerInputVoltage === 2,
                                            'text-danger': t.severity_status[0].chargerInputVoltage === 3,
                                            '': t.severity_status[0].chargerInputVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.charger_input_voltage}
                                </span>
                            ),
                            chargerOutputVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].chargerOutputVoltage === 1,
                                            'text-warning': t.severity_status[0].chargerOutputVoltage === 2,
                                            'text-danger': t.severity_status[0].chargerOutputVoltage === 3,
                                            '': t.severity_status[0].chargerOutputVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.charger_output_voltage}
                                </span>
                            ),
                            chargerOutputCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].chargerOutputCurrent === 1,
                                            'text-warning': t.severity_status[0].chargerOutputCurrent === 2,
                                            'text-danger': t.severity_status[0].chargerOutputCurrent === 3,
                                            '': t.severity_status[0].chargerOutputCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.charger_output_current}
                                </span>
                            ),
                        };
                    });
                    setBatteriesData(dataArray);
                }
                if (response.ips.length > 0) {
                    let dataArray = await response.ips.map((t) => {
                        return {
                            id: t._id,
                            ips: t.ips,
                            inputVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].inputVoltage === 1,
                                            'text-warning': t.severity_status[0].inputVoltage === 2,
                                            'text-danger': t.severity_status[0].inputVoltage === 3,
                                            '': t.severity_status[0].inputVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.input_voltage}
                                </span>
                            ),
                            inputCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].inputCurrent === 1,
                                            'text-warning': t.severity_status[0].inputCurrent === 2,
                                            'text-danger': t.severity_status[0].inputCurrent === 3,
                                            '': t.severity_status[0].inputCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.input_current}
                                </span>
                            ),
                            frbcOutputVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].frbcOutputVoltage === 1,
                                            'text-warning': t.severity_status[0].frbcOutputVoltage === 2,
                                            'text-danger': t.severity_status[0].frbcOutputVoltage === 3,
                                            '': t.severity_status[0].frbcOutputVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.frbc_output_voltage}
                                </span>
                            ),
                            batteryOutputVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].batteryOutputVoltage === 1,
                                            'text-warning': t.severity_status[0].batteryOutputVoltage === 2,
                                            'text-danger': t.severity_status[0].batteryOutputVoltage === 3,
                                            '': t.severity_status[0].batteryOutputVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.battery_output_voltage}
                                </span>
                            ),
                            batteryChargingCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].batteryChargingCurrent === 1,
                                            'text-warning': t.severity_status[0].batteryChargingCurrent === 2,
                                            'text-danger': t.severity_status[0].batteryChargingCurrent === 3,
                                            '': t.severity_status[0].batteryChargingCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.battery_charging_current}
                                </span>
                            ),
                            dcConverterOutputCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].dcConverterOutputCurrent === 1,
                                            'text-warning': t.severity_status[0].dcConverterOutputCurrent === 2,
                                            'text-danger': t.severity_status[0].dcConverterOutputCurrent === 3,
                                            '': t.severity_status[0].dcConverterOutputCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.dc_converter_output_current}
                                </span>
                            ),
                            dcConverterOutputVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].dcConverterOutputVoltage === 1,
                                            'text-warning': t.severity_status[0].dcConverterOutputVoltage === 2,
                                            'text-danger': t.severity_status[0].dcConverterOutputVoltage === 3,
                                            '': t.severity_status[0].dcConverterOutputVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.dc_converter_output_voltage}
                                </span>
                            ),
                            inverterInputVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].inverterInputVoltage === 1,
                                            'text-warning': t.severity_status[0].inverterInputVoltage === 2,
                                            'text-danger': t.severity_status[0].inverterInputVoltage === 3,
                                            '': t.severity_status[0].inverterInputVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.inverter_input_voltage}
                                </span>
                            ),
                            inverterOutputVoltage: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].inverterOutputVoltage === 1,
                                            'text-warning': t.severity_status[0].inverterOutputVoltage === 2,
                                            'text-danger': t.severity_status[0].inverterOutputVoltage === 3,
                                            '': t.severity_status[0].inverterOutputVoltage === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.inverter_output_voltage}
                                </span>
                            ),
                            inverterOutputCurrent: (
                                <span
                                    className={classNames(
                                        {
                                            'text-success': t.severity_status[0].inverterOutputCurrent === 1,
                                            'text-warning': t.severity_status[0].inverterOutputCurrent === 2,
                                            'text-danger': t.severity_status[0].inverterOutputCurrent === 3,
                                            '': t.severity_status[0].inverterOutputCurrent === '',
                                        },
                                        'fw-semibold'
                                    )}>
                                    {t.inverter_output_current}
                                </span>
                            ),
                            healthOfIPSModulesThroughPFC: (
                                <Badge
                                    bg=""
                                    className={classNames(
                                        'me-1',
                                        t.health_of_ips_modules_through_pfc == 'UNHEALTHY'
                                            ? 'badge-danger-lighten'
                                            : 'badge-success-lighten'
                                    )}>
                                    {t.health_of_ips_modules_through_pfc == 'UNHEALTHY'
                                        ? 'UNHEALTHY'
                                        : t.health_of_ips_modules_through_pfc == 'HEALTHY'
                                        ? 'HEALTHY'
                                        : t.health_of_ips_modules_through_pfc == 'AVAILABLE'
                                        ? 'AVAILABLE'
                                        : ''}
                                </Badge>
                            ),
                            availabilityOfStandbyPowerSupply: (
                                <Badge
                                    bg=""
                                    className={classNames(
                                        'me-1',
                                        t.availability_of_stand_by_power_supply == 'UNHEALTHY'
                                            ? 'badge-danger-lighten'
                                            : 'badge-success-lighten'
                                    )}>
                                    {t.availability_of_stand_by_power_supply == 'UNHEALTHY'
                                        ? 'UNHEALTHY'
                                        : t.availability_of_stand_by_power_supply == 'HEALTHY'
                                        ? 'HEALTHY'
                                        : t.availability_of_stand_by_power_supply == 'AVAILABLE'
                                        ? 'AVAILABLE'
                                        : ''}
                                </Badge>
                            ),
                        };
                    });
                    setIPSData(dataArray);
                }
            }
        } else {
            toast.error('Please select all fields', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        Loading();
    };

    const dcCircuitColumns = [
        {
            Header: t('Track Number'),
            accessor: 'trackNumber',
            sort: true,
        },
        {
            Header: t('Feed End Voltage'),
            accessor: 'feedEndVoltage',
            sort: false,
        },
        {
            Header: t('Relay End Voltage'),
            accessor: 'relayEndVoltage',
            sort: false,
        },

        {
            Header: t('Charger Current'),
            accessor: 'chargerCurrent',
            sort: false,
        },
        {
            Header: t('TPR Status'),
            accessor: 'tprStatus',
            sort: false,
        },
        {
            // Header: t('Charger Potential Free Contact'),
            Header: () => (
                <span>
                    Charger Potential <br />
                    Free Contact
                </span>
            ),
            accessor: 'chargerPotentialFreeContact',
            sort: false,
            size: 50,
        },
        {
            Header: t('Relay End Current'),
            accessor: 'relayEndCurrent',
            sort: false,
        },
        {
            // Header: t('Feed End Current'),
            Header: () => (
                <span>
                    Feed <br />
                    End Current
                </span>
            ),
            accessor: 'feedEndCurrent',
            sort: false,
        },
    ];

    const pointMachineColumns = [
        {
            Header: t('Point Machine'),
            accessor: 'pointMachine',
            sort: true,
        },
        {
            Header: t('Forward Operating Current'),
            accessor: 'forwardOperatingCurrent',
            sort: true,
        },
        {
            Header: t('Reverse Operating Current'),
            accessor: 'reverseOperatingCurrent',
            sort: true,
        },
        {
            Header: t('Forward Operating Voltage'),
            accessor: 'forwardOperatingVoltage',
            sort: false,
        },
        {
            Header: t('Reverse Operating Voltage'),
            accessor: 'reverseOperatingVoltage',
            sort: false,
        },
        {
            Header: t('Point Position Voltage'),
            accessor: 'pointPositionVoltage',
            sort: false,
        },
        {
            Header: t('Point Position Relay'),
            accessor: 'pointPositionRelay',
            sort: false,
        },
    ];

    const signalColumns = [
        {
            Header: t('Signal Number'),
            accessor: 'signalNumber',
            sort: true,
        },
        {
            Header: () => (
                <div
                    style={{
                        color: '#ff0000',
                    }}>
                    {t('AC Voltage')}
                </div>
            ),
            accessor: 'redAcVoltage',
            sort: false,
        },
        {
            Header: () => (
                <div
                    style={{
                        color: '#ff0000',
                    }}>
                    {t('AC Current')}
                </div>
            ),
            accessor: 'redAcCurrent',
            sort: false,
        },
        {
            Header: () => (
                <div
                    style={{
                        color: '#34cb4a',
                    }}>
                    {t('AC Voltage')}
                </div>
            ),
            accessor: 'greenAcVoltage',
            sort: false,
        },

        {
            Header: () => (
                <div
                    style={{
                        color: '#34cb4a',
                    }}>
                    {t('AC Current')}
                </div>
            ),
            accessor: 'greenAcCurrent',
            sort: false,
        },
        {
            Header: () => (
                <div
                    style={{
                        color: '#fdbe40',
                    }}>
                    {t('AC Voltage')}
                </div>
            ),
            accessor: 'yellowAcVoltage',
            sort: false,
        },
        {
            Header: () => (
                <div
                    style={{
                        color: '#fdbe40',
                    }}>
                    {t('AC Current')}
                </div>
            ),
            accessor: 'yellowAcCurrent',
            sort: false,
        },
        {
            Header: () => (
                <div
                    style={{
                        color: '#fdbe40',
                    }}>
                    {t('AC Voltage')}
                </div>
            ),
            accessor: 'otherAcVoltage',
            sort: false,
        },
        {
            Header: () => (
                <div
                    style={{
                        color: '#fdbe40',
                    }}>
                    {t('AC Current')}
                </div>
            ),
            accessor: 'otherAcCurrent',
            sort: false,
        },
        {
            Header: t('Status'),
            accessor: 'status',
            sort: false,
        },
    ];

    const batteriesColumns = [
        {
            Header: t('Battery Charger'),
            accessor: 'batteryCharger',
            sort: true,
        },
        {
            Header: t('Charger Input Voltage'),
            accessor: 'chargerInputVoltage',
            sort: false,
        },
        {
            Header: t('Charger Output Voltage'),
            accessor: 'chargerOutputVoltage',
            sort: false,
        },
        {
            Header: t('Charger Output Current'),
            accessor: 'chargerOutputCurrent',
            sort: false,
        },
    ];

    const ipsColumns = [
        {
            Header: t('IPS'),
            accessor: 'ips',
            sort: true,
        },
        {
            id: '1',
            Header: () => (
                <div
                    style={{
                        textAlign: 'center',
                    }}>
                    {t('Input')}
                </div>
            ),
            columns: [
                {
                    Header: t('Voltage'),
                    accessor: 'inputVoltage',
                },
                {
                    Header: t('Current'),
                    accessor: 'inputCurrent',
                },
            ],
        },
        {
            Header: t('FRBC Output Voltage'),
            accessor: 'frbcOutputVoltage',
            sort: false,
        },
        {
            id: '2',
            Header: () => (
                <div
                    style={{
                        textAlign: 'center',
                    }}>
                    {t(' Battery')}
                </div>
            ),
            columns: [
                {
                    Header: t('Output Voltage'),
                    accessor: 'batteryOutputVoltage',
                },
                {
                    Header: t('Charging Current'),
                    accessor: 'batteryChargingCurrent',
                },
            ],
        },
        {
            id: '3',
            Header: () => (
                <div
                    style={{
                        textAlign: 'center',
                    }}>
                    {t('DC-DC-Converter')}
                </div>
            ),
            columns: [
                {
                    Header: t('Output Current'),
                    accessor: 'dcConverterOutputCurrent',
                },
                {
                    Header: t('Output Voltage'),
                    accessor: 'dcConverterOutputVoltage',
                },
            ],
        },
        {
            id: '4',
            Header: () => (
                <div
                    style={{
                        textAlign: 'center',
                    }}>
                    {t(' Inverter')}
                </div>
            ),
            columns: [
                {
                    Header: t('Input Voltage'),
                    accessor: 'inverterInputVoltage',
                },
                {
                    Header: t('Output Voltage'),
                    accessor: 'inverterOutputVoltage',
                },
                {
                    Header: t('Output Current'),
                    accessor: 'inverterOutputCurrent',
                },
            ],
        },

        {
            Header: t('Health of IPS modules through PFC'),
            accessor: 'healthOfIPSModulesThroughPFC',
            sort: false,
        },
        {
            Header: t('Availability of standby Power Supply'),
            accessor: 'availabilityOfStandbyPowerSupply',
            sort: false,
        },
    ];

    const inputRef = useRef(null);
    const printDocument = async () => {
        Loading();
        var options = {
            backgroundColor: '#f1f3fa', //background is transparent if you don't set it, which turns it black for some reason.
        };

        await html2canvas(inputRef.current, options, {
            allowTaint: true,
            useCORS: true,
            logging: false,
        }).then((canvas) => {
            const componentWidth = inputRef.current.offsetWidth;
            const componentHeight = inputRef.current.offsetHeight;

            const orientation = componentWidth >= componentHeight ? 'l' : 'p';
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation,
                unit: 'px',
                compress: true,
            });

            pdf.internal.pageSize.width = componentWidth;
            pdf.internal.pageSize.height = componentHeight;
            pdf.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
            pdf.save('RDPMS Update Report -' + moment(new Date()).format('DD-MMM-YYYY') + '.pdf');

            // pdf.save(
            //     'RDPMS ' + dcCircuitData.length > 0
            //         ? 'Dc Circuit,'
            //         : pointMachineData.length > 0
            //         ? 'Point Machine,'
            //         : signalData.length > 0
            //         ? 'Signal,'
            //         : batteriesData.length > 0
            //         ? 'Batteries,'
            //         : ipsData.length > 0
            //         ? 'IPS'
            //         : '' + 'Update-' + moment(new Date()).format('DD-MMM-YYYY') + '.pdf'
            // );
        });
        Loading();
    };

    return (
        <>
            <ToastContainer />
            <Row>
                <Col>
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <form className="d-flex">
                                <div className="input-group">
                                    {/* <HyperDatepicker
                                        value={selectedDate}
                                        inputClass="form-control-light"
                                        onChange={(date) => {
                                            // onDateChange(date);
                                        }}
                                    /> */}
                                </div>
                                <Button
                                    variant="success"
                                    onClick={() => {
                                        printDocument();
                                    }}>
                                    Export
                                </Button>
                                <button className="btn btn-primary ms-2">
                                    <i className="mdi mdi-autorenew"></i>
                                </button>
                            </form>
                        </div>
                        <h4 className="page-title">Reports Alert</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <label>Station</label> <br />
                                        <FormInput
                                            name="select"
                                            type="select"
                                            className="form-select"
                                            key="select"
                                            onChange={(e) => setSelectedStation(e.target.value)}>
                                            <option value="">Select station</option>
                                            {station &&
                                                station.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                        </FormInput>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <label>Category</label> <br />
                                        <FormInput
                                            name="select"
                                            type="select"
                                            className="form-select"
                                            key="select"
                                            onChange={(e) => setSelectedCategory(e.target.value)}>
                                            <option value="">Select Category</option>
                                            <option value="all">ALL</option>
                                            <option value="dcCircuit">DC CIRCUIT</option>
                                            <option value="pointMachine">POINT MACHINE</option>
                                            <option value="signals">SIGNALS</option>
                                            <option value="batteries">BATTERIES</option>
                                            <option value="ips">IPS</option>
                                            <option value="axialCounters">AXIAL COUNTERS</option>
                                            <option value="dataLogger">DATA LOGGER</option>
                                            <option value="electronicsInterlocking">ELECTRONIC INTERLOCKING</option>
                                        </FormInput>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <label>Start Date</label> <br />
                                        <HyperDatepicker
                                            hideAddon={true}
                                            dateFormat="yyyy-MM-dd"
                                            value={selectedStartDate}
                                            onChange={(date) => {
                                                setSelectedStartDate(date);
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <label>End Date</label> <br />
                                        <HyperDatepicker
                                            hideAddon={true}
                                            dateFormat="yyyy-MM-dd"
                                            value={selectedEndDate}
                                            onChange={(date) => {
                                                setSelectedEndDate(date);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Button variant="primary" onClick={handleGetReport}>
                                Submit
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div ref={inputRef}>
                {dcCircuitData.length > 0 ? (
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header as="h4">
                                    DC Circuit
                                    <Row>
                                        <Col
                                            style={{
                                                fontSize: '16px',
                                                marginTop: '15px',
                                            }}>
                                            {' ' +
                                                'Start Date : ' +
                                                moment(selectedStartDate).format('Do MMM YYYY') +
                                                ' To End Date : ' +
                                                moment(selectedEndDate).format('Do MMM YYYY')}
                                        </Col>
                                        <Col>
                                            <div className="text-sm-end">
                                                <img
                                                    src={reportImg}
                                                    alt="hamingbird"
                                                    style={{
                                                        width: '25%',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Table
                                        columns={dcCircuitColumns}
                                        data={dcCircuitData}
                                        isSortable={true}
                                        isSearchable={true}
                                    />
                                </Card.Body>
                                <Card.Footer>Copyright 2023 Hummingbird TekSystems, Inc.| CONFIDENTIAL</Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    ''
                )}

                {pointMachineData.length > 0 ? (
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header as="h4">
                                    Point Machine
                                    <Row>
                                        <Col
                                            style={{
                                                fontSize: '16px',
                                                marginTop: '15px',
                                            }}>
                                            {' ' +
                                                'Start Date : ' +
                                                moment(selectedStartDate).format('Do MMM YYYY') +
                                                ' To End Date : ' +
                                                moment(selectedEndDate).format('Do MMM YYYY')}
                                        </Col>
                                        <Col>
                                            <div className="text-sm-end">
                                                <img
                                                    src={reportImg}
                                                    alt="hamingbird"
                                                    style={{
                                                        width: '25%',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Table
                                        columns={pointMachineColumns}
                                        data={pointMachineData}
                                        isSortable={true}
                                        isSearchable={true}
                                    />
                                </Card.Body>
                                <Card.Footer>Copyright 2023 Hummingbird TekSystems, Inc.| CONFIDENTIAL</Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    ''
                )}

                {signalData.length > 0 ? (
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header as="h4">
                                    Signal
                                    <Row>
                                        <Col
                                            style={{
                                                fontSize: '16px',
                                                marginTop: '15px',
                                            }}>
                                            {' ' +
                                                'Start Date : ' +
                                                moment(selectedStartDate).format('Do MMM YYYY') +
                                                ' To End Date : ' +
                                                moment(selectedEndDate).format('Do MMM YYYY')}
                                        </Col>
                                        <Col>
                                            <div className="text-sm-end">
                                                <img
                                                    src={reportImg}
                                                    alt="hamingbird"
                                                    style={{
                                                        width: '25%',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Table
                                        columns={signalColumns}
                                        data={signalData}
                                        isSortable={true}
                                        isSearchable={true}
                                    />
                                </Card.Body>
                                <Card.Footer>Copyright 2023 Hummingbird TekSystems, Inc.| CONFIDENTIAL</Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    ''
                )}

                {batteriesData.length > 0 ? (
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header as="h4">
                                    Batteries
                                    <Row>
                                        <Col
                                            style={{
                                                fontSize: '16px',
                                                marginTop: '15px',
                                            }}>
                                            {' ' +
                                                'Start Date : ' +
                                                moment(selectedStartDate).format('Do MMM YYYY') +
                                                ' To End Date : ' +
                                                moment(selectedEndDate).format('Do MMM YYYY')}
                                        </Col>
                                        <Col>
                                            <div className="text-sm-end">
                                                <img
                                                    src={reportImg}
                                                    alt="hamingbird"
                                                    style={{
                                                        width: '25%',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Table
                                        columns={batteriesColumns}
                                        data={batteriesData}
                                        isSortable={true}
                                        isSearchable={true}
                                    />
                                </Card.Body>
                                <Card.Footer>Copyright 2023 Hummingbird TekSystems, Inc.| CONFIDENTIAL</Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    ''
                )}

                {ipsData.length > 0 ? (
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header as="h4">
                                    IPS
                                    <Row>
                                        <Col
                                            style={{
                                                fontSize: '16px',
                                                marginTop: '15px',
                                            }}>
                                            {' ' +
                                                'Start Date : ' +
                                                moment(selectedStartDate).format('Do MMM YYYY') +
                                                ' To End Date : ' +
                                                moment(selectedEndDate).format('Do MMM YYYY')}
                                        </Col>
                                        <Col>
                                            <div className="text-sm-end">
                                                <img
                                                    src={reportImg}
                                                    alt="hamingbird"
                                                    style={{
                                                        width: '25%',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Table columns={ipsColumns} data={ipsData} isSortable={true} isSearchable={true} />
                                </Card.Body>
                                <Card.Footer>Copyright 2023 Hummingbird TekSystems, Inc.| CONFIDENTIAL</Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default ReportsAlert;
