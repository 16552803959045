// @flow
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import classNames from 'classnames';
// components
import Table from '../../components/Table';

import { useTranslation } from 'react-i18next';
import { Loading } from 'react-loading-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import Papa from 'papaparse';
import { getBatteryChargerAPI, getDeviceDataApi, getNotification } from '../../API/RDPMS_API';
import moment from 'moment';
import { FormInput } from '../../components';

const Alert = () => {
    const { auth } = useSelector((state) => ({
        auth: state.Auth,
    }));
    let stationIds = [sessionStorage.getItem('stationId')];

    const navigate = useNavigate();

    const [stationsName, setStationsName] = useState(sessionStorage.getItem('stationName'));

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const [selectedDevice, setselectedDevice] = useState('All');

    const [device, setDevice] = useState(['All', 'DC Circuit', 'Point Machine', 'Signal', 'Batteries', 'IPS']);

    useEffect(() => {
        getNotificationData();
    }, []);

    const { t } = useTranslation();

    const getNotificationData = async (limits, pages, device) => {
        Loading();
        let limitNumber = limits ? limits : limit;
        let pagesNumber = pages ? pages : pageNumber;
        let selectedDevices = device ? device : selectedDevice;

        let request = {
            limit: limitNumber,
            page: pagesNumber,
            stationId: stationIds,
            selectedDevice: selectedDevices,
        };
        let response = await getNotification(request);
        console.log(response, 'getNotification response 123');

        if (response && response.data && response.data.length > 0) {
            // let deviceName = ['All'];
            let dataArray = await response.data.map((t) => {
                // let found = deviceName.includes(t.device_name);
                // if (!found) deviceName.push(t.device_name);

                return {
                    id: t._id,
                    archiveId: t.archive_id,
                    deviceType: t.device_name,
                    time: moment(t.time).format('Do MMM, h:mm a'),
                    stationName: stationsName,
                    deviceName: t.name,
                    deviceId: t.device_id,
                    // attribute: t.attribute_name,
                    // actualValue: t.actual_value,
                    // min: t.min_value,
                    // max: t.max_value,
                    message: t.archive_id ? 'Data not in limits' : 'No data received',
                    view: t.archive_id ? (
                        <div>
                            <i className="dripicons-preview" key={t.id} onClick={() => viewNotification(t)}></i>
                        </div>
                    ) : (
                        ''
                    ),
                };
            });

            // setDevice(deviceName);

            setPageNumber(response.currentPage);
            setPageCount(response.totalPages);
            setData(dataArray);
            setTotalCount(response.totalCount);

            toast.success('Fetching alert successfully for ' + selectedDevices + ' device', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error('No data found for ' + selectedDevices + ' device', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        Loading();
    };

    const viewNotification = async (value) => {
        if (value.device_name === 'DC Circuit') {
            let request = {
                deviceName: value.device_name,
                archiveId: value.archive_id,
                masterId: value.master_id,
                stationId: value.station_id,
            };
            let response = await getDeviceDataApi(request);

            if (response) {
                sessionStorage.setItem('dcCircuitInfo', JSON.stringify(response));
                navigate('/rdpms/dcCircuitOverviewPage');
            }
        } else if (value.device_name === 'Point Machine') {
            let request = {
                deviceName: value.device_name,
                archiveId: value.archive_id,
                masterId: value.master_id,
                stationId: value.station_id,
            };
            let response = await getDeviceDataApi(request);

            if (response) {
                sessionStorage.setItem('pointMachineInfo', JSON.stringify(response));
                navigate('/rdpms/pointMachineDetails');
            }
        } else if (value.device_name === 'Signal') {
            let request = {
                deviceName: value.device_name,
                archiveId: value.archive_id,
                masterId: value.master_id,
                stationId: value.station_id,
            };
            let response = await getDeviceDataApi(request);

            if (response) {
                sessionStorage.setItem('signalInfo', JSON.stringify(response));
                navigate('/rdpms/signalOverviewPage');
            }
        } else if (value.device_name === 'Batteries') {
            let request = {
                deviceName: value.device_name,
                archiveId: value.archive_id,
                masterId: value.master_id,
                stationId: value.station_id,
            };
            let response = await getDeviceDataApi(request);

            if (response) {
                sessionStorage.setItem('batteryChargerInfo', JSON.stringify(response));
                navigate('/rdpms/batteryChargerPage');
            }
        } else if (value.device_name === 'IPS') {
            let request = {
                deviceName: value.device_name,
                archiveId: value.archive_id,
                masterId: value.master_id,
                stationId: value.station_id,
            };
            let response = await getDeviceDataApi(request);

            if (response) {
                sessionStorage.setItem('ipsInfo', JSON.stringify(response));
                navigate('/rdpms/ipsOverviewPage');
            }
        }
    };

    //pagination
    const handlePageChange = async (value) => {
        setPageNumber(1);
        setLimit(value);
        await getNotificationData(value, 1);
    };

    const handlePageClick = async (value) => {
        setPageNumber(value);
        await getNotificationData(limit, value);
    };

    /*
     * handle date change
     */
    const onDateChange = (date) => {
        if (date) {
            setSelectedDate(date);
        }
    };

    const handleDevice = (e) => {
        setselectedDevice(e.target.value);
        getNotificationData(5, 1, e.target.value);
    };

    const columns = [
        {
            Header: t('Time'),
            accessor: 'time',
            sort: false,
        },
        {
            Header: t('Device Type'),
            accessor: 'deviceType',
            sort: true,
        },
        {
            Header: t('Station Name'),
            accessor: 'stationName',
            sort: true,
        },
        {
            Header: t('Device Name'),
            accessor: 'deviceName',
            sort: true,
        },
        {
            Header: t('Device Id'),
            accessor: 'deviceId',
            sort: false,
        },
        // {
        //     Header: t('Attribute Name'),
        //     accessor: 'attribute',
        //     sort: false,
        // },

        // {
        //     Header: t('Actual Value'),
        //     accessor: 'actualValue',
        //     sort: false,
        // },

        // {
        //     Header: t('Minimum'),
        //     accessor: 'min',
        //     sort: false,
        // },

        // {
        //     Header: t('Maximum'),
        //     accessor: 'max',
        //     sort: false,
        // },

        {
            Header: t('Message'),
            accessor: 'message',
            sort: false,
        },
        {
            Header: t('View'),
            accessor: 'view',
            sort: false,
        },
    ];

    const sizePerPageList = [
        {
            text: '5',
            value: 5,
        },
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
    ];

    return (
        <>
            <ToastContainer />
            <Row>
                <Col>
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <form className="d-flex">
                                <div className="input-group">
                                    <FormInput
                                        name="select"
                                        type="select"
                                        className="form-select"
                                        key="select"
                                        onChange={(e) => handleDevice(e)}>
                                        {device &&
                                            device.map((item, index) => {
                                                return (
                                                    <option key={index} value={item}>
                                                        {item}
                                                    </option>
                                                );
                                            })}
                                    </FormInput>
                                    <button className="btn btn-primary ms-2">
                                        <i className="mdi mdi-autorenew"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <h4 className="page-title">{t('Alert')}</h4>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Table
                                columns={columns}
                                data={data}
                                pageSize={limit}
                                pageCount={pageCount}
                                pageNumber={pageNumber}
                                totalCount={totalCount}
                                sizePerPageList={sizePerPageList}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                                onPageChange={handlePageChange}
                                onPageClick={handlePageClick}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Alert;
