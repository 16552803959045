import React from 'react';
import Chart from 'react-apexcharts';

const ChargerPotentialFreeContactStepLineChart = ({ data }) => {
    const apexLineChartWithLables = {
        chart: {
            height: 364,
            type: 'line',
            dropShadow: {
                enabled: true,
                opacity: 0.1,
                blur: 7,
                left: -7,
                top: 7,
            },
            toolbar: {
                show: true,
            },
            parentHeightOffset: 0,
        },
        grid: {
            padding: {
                left: 0,
                right: 0,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: ['stepline'],
            width: 4,
        },
        zoom: {
            enabled: true,
        },
        // legend: {
        //     position: 'top',
        //     horizontalAlign: 'right',
        //     showForSingleSeries: true,
        // show: true,
        // },
        // markers: {
        //     size: [5, 7],
        // },
        colors: ['#979797'],
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'dd MMM yyyy',
            },
            tooltip: {
                enabled: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            type: 'value',
            min: 0,
            max: 1,
            forceNiceScale: true,
        },
    };

    const apexLineChartWithLablesData = [
        {
            name: 'Charger Potential Free Contact',
            data: data,
        },
    ];

    return (
        <Chart
            options={apexLineChartWithLables}
            series={apexLineChartWithLablesData}
            type="line"
            className="apex-charts mt-3"
            height={378}
        />
    );
};

export default ChargerPotentialFreeContactStepLineChart;

// import ReactEChart from 'echarts-for-react';
// export default function ChargerPotentialFreeContactStepLineChart({ color, data }) {
//     var colorPalette = [color];

//     const eChartsOption = {
//         xAxis: {
//             type: 'category',
//         },
//         yAxis: {
//             type: 'value',
//         },
//         legend: {
//             right: 0,
//             data: ['Charger Potential Free Contact'],
//         },
//         dataZoom: [
//             {
//                 type: 'inside',
//                 xAxisIndex: 0,
//                 minSpan: 5,
//             },
//             {
//                 type: 'slider',
//                 xAxisIndex: 0,
//                 minSpan: 5,
//                 bottom: 7,
//             },
//         ],
//         series: [
//             {
//                 name: 'Charger Potential Free Contact',
//                 type: 'line',
//                 step: 'end',
//                 data: data,
//             },
//         ],
//         color: colorPalette,
//     };
//     return (
//         <div>
//             <ReactEChart
//                 option={eChartsOption}
//                 style={{
//                     height: '250px',
//                 }}
//             />
//         </div>
//     );
// }
