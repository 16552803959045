import config from '../config';
import axios from 'axios';
import Logout from '../pages/account/Logout';

const baseUrl = config.RDPMS_API_URLS;

const getUserFromSession = () => {
    const user = sessionStorage.getItem('hyper_user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const getAxios = async () => {
    let user = await getUserFromSession();
    if (user) {
        const { token } = user;
        if (token) {
            return axios.create({
                baseURL: baseUrl,
                headers: {
                    'Cache-Control': 'no-cache',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    // authorization: `Bearer ${token}`,
                },
            });
        }
    }
};

const getLogout = async () => {
    <Logout />;
};

export async function getUserProfile(id) {
    const axios = await getAxios();
    return await axios.post('/user/' + id).then((response) => {
        let profileResponse = response.data;
        if (profileResponse.isSucess === true) {
            return profileResponse.response;
        } else if (profileResponse.isSucess === false) {
            getLogout();
        } else {
            return profileResponse.response;
        }
    });
}

export async function changePassword(value) {
    return await axios.post(baseUrl + '/user/change/password/' + value.id, value).then((response) => {
        let passwordResponse = response.data;
        if (passwordResponse.isSucess === true) {
            return passwordResponse.response;
        } else {
            return passwordResponse.response;
        }
    });
}

export async function updateProfile(value) {
    return await axios.post(baseUrl + '/user/change/profile/' + value.id, value).then((response) => {
        let profileResponse = response.data;
        if (profileResponse.isSucess === true) {
            return profileResponse.response;
        } else {
            return profileResponse.response;
        }
    });
}

//All API
export async function getDcCircuitAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit', value).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function getDcCircuitExportData(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit/export', value).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function getDcCircuitArchieveAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/dccircuit', value).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function getDcCircuitArchieveAlertAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/dccircuit/alert', value).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function getFeedEndCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit/feedendcurrent', value).then((response) => {
        let feedEndCurrentResponse = response.data;
        if (feedEndCurrentResponse.isSucess === true) {
            return feedEndCurrentResponse.response;
        } else if (feedEndCurrentResponse.isSucess === false && feedEndCurrentResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return feedEndCurrentResponse.response;
        }
    });
}

export async function getFeedEndVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit/feedendvoltage', value).then((response) => {
        let feedEndVoltageResponse = response.data;
        if (feedEndVoltageResponse.isSucess === true) {
            return feedEndVoltageResponse.response;
        } else if (feedEndVoltageResponse.isSucess === false && feedEndVoltageResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return feedEndVoltageResponse.response;
        }
    });
}

export async function getRelayEndVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit/relayendvoltage', value).then((response) => {
        let relayEndVoltageResponse = response.data;
        if (relayEndVoltageResponse.isSucess === true) {
            return relayEndVoltageResponse.response;
        } else if (relayEndVoltageResponse.isSucess === false && relayEndVoltageResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return relayEndVoltageResponse.response;
        }
    });
}

export async function getRelayEndCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit/relayendcurrent', value).then((response) => {
        let relayEndCurrentResponse = response.data;
        if (relayEndCurrentResponse.isSucess === true) {
            return relayEndCurrentResponse.response;
        } else if (relayEndCurrentResponse.isSucess === false && relayEndCurrentResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return relayEndCurrentResponse.response;
        }
    });
}

export async function getChargerCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit/chargercurrent', value).then((response) => {
        let chargerCurrentResponse = response.data;
        if (chargerCurrentResponse.isSucess === true) {
            return chargerCurrentResponse.response;
        } else if (chargerCurrentResponse.isSucess === false && chargerCurrentResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return chargerCurrentResponse.response;
        }
    });
}

export async function getTPRGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit/tpr', value).then((response) => {
        let tprResponse = response.data;
        if (tprResponse.isSucess === true) {
            return tprResponse.response;
        } else if (tprResponse.isSucess === false && tprResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return tprResponse.response;
        }
    });
}

export async function getChargerPotentialFreeContactAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dccircuit/chargerpotentialfreecontact', value).then((response) => {
        let chargerPotentialFreeContactResponse = response.data;
        if (chargerPotentialFreeContactResponse.isSucess === true) {
            return chargerPotentialFreeContactResponse.response;
        } else if (
            chargerPotentialFreeContactResponse.isSucess === false &&
            chargerPotentialFreeContactResponse.error === 'Invalid token'
        ) {
            getLogout();
        } else {
            return chargerPotentialFreeContactResponse.response;
        }
    });
}

export async function getIPSOverviewAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getIPSOverviewExportData(value) {
    const axios = await getAxios();
    return await axios.post('/ips/export', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getIPSArchieveAlertAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/ips/alert', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getInputVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/inputvoltage', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getInputCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/inputcurrent', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getBatteryOutputVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/batteryoutputvoltage', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getBatteryChargingCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/batterychargingcurrent', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getDcConverterOutputVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/dcconverteroutputvoltage', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getDcConverterOutputCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/dcconverteroutputcurrent', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getInverterInputVoltageAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/inverterinputvoltage', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getInverterOutputCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/inverteroutputcurrent', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getInverterOutputVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/inverteroutputvoltage', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getFRBCOutputVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/frbcoutputvoltage', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getHealthOfIPSModulesThroughPFCGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/healthofipsmodulesthroughpfc', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getAvailabilityOfStandByPowerSupplyAPI(value) {
    const axios = await getAxios();
    return await axios.post('/ips/availabilityofstandbypowersupply', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getIPSOverviewArchieveAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/ips', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getIPSOverviewArchieveAlertAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/ips/alert', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getPointMachineOverviewAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getPointMachineOverviewExportData(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/export', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getForwardOperatingCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/forwardoperatingcurrent', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getReverseOperatingCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/reverseoperatingcurrent', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getForwardOperatingVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/forwardoperatingvoltage', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getReverseOperatingVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/reverseoperatingvoltage', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getTimeOfOperationGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/timeofoperation', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getPointPositionVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/pointposition', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getVibrationGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/vibration', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getPointPositionRelayGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/pointmachine/relay', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getPointMachineArchieveAlertAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/pointmachine/alert', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getPointMachineOverviewArchieveAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/pointmachine', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getPointMachineOverviewArchieveAlertAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/pointmachine/alert', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getBatteryChargerAPI(value) {
    const axios = await getAxios();
    return await axios.post('/batterycharger', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getBatteryChargerExportData(value) {
    const axios = await getAxios();
    return await axios.post('/batterycharger/export', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getChargerInputVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/batterycharger/chargerinputvoltage', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getChargerInputCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/batterycharger/chargerinputcurrent', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getChargerOutputVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/batterycharger/chargeroutputvoltage', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getChargerOutputCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/batterycharger/chargeroutputcurrent', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getChargerBatteryOutputVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/batterycharger/batteryoutputvoltage', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getChargerBatteryOutputCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/batterycharger/batteryoutputcurrent', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getBatteryChargerArchieveAlertAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/batterycharger/alert', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getbatteryChargerArchieveAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/batterycharger', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getDashboardDcCircuitAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dashboard/dccircuit', value).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function getLastDcCircuitAPI() {
    const axios = await getAxios();
    return await axios.post('/dashboard/dccircuit/dot').then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function getDashboardPointMachineAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dashboard/pointmachine', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function getDashboardBatteryChargerAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dashboard/batterycharger', value).then((response) => {
        let batteryChargerResponse = response.data;
        if (batteryChargerResponse.isSucess === true) {
            return batteryChargerResponse.response;
        } else if (batteryChargerResponse.isSucess === false && batteryChargerResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteryChargerResponse.response;
        }
    });
}

export async function getDashboardIPSAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dashboard/ips', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function getDashboardSignalAPI(value) {
    const axios = await getAxios();
    return await axios.post('/dashboard/signal', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getSignalAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getSignalExportData(value) {
    const axios = await getAxios();
    return await axios.post('/signal/export', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getRedAcCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal/redaccurrent', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getRedAcVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal/redacvoltage', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getGreenAcCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal/greenaccurrent', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getGreenAcVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal/greenacvoltage', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getYellowAcCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal/yellowaccurrent', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getYellowAcVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal/yellowacvoltage', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getOtherAcCurrentGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal/otheraccurrent', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getOtherAcVoltageGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/signal/otheracvoltage', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getSignalAlertAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/signal/alert', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getSignalArchieveAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/signal', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getSignalArchieveAlertAPI(value) {
    const axios = await getAxios();
    return await axios.post('/archieve/signal/alert', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

//add all master
// add dc circuit
export async function getMasterDcCircuitAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/dccircuit', value).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function addDCCircuitAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/dccircuit/create', value).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function editDCCircuitAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/dccircuit/update/' + value.id, value).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

export async function deleteDCCircuitAPI(id) {
    const axios = await getAxios();
    return await axios.post('/master/dccircuit/delete/' + id).then((response) => {
        let dcCircuitResponse = response.data;
        if (dcCircuitResponse.isSucess === true) {
            return dcCircuitResponse.response;
        } else if (dcCircuitResponse.isSucess === false && dcCircuitResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return dcCircuitResponse.response;
        }
    });
}

// add point machine
export async function getMasterPointMachineAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/pointmachine', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function addPointMachineAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/pointmachine/create', value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function editPointMachineAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/pointmachine/update/' + value.id, value).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

export async function deletePointMachineAPI(id) {
    const axios = await getAxios();
    return await axios.post('/master/pointmachine/delete/' + id).then((response) => {
        let pointMachineResponse = response.data;
        if (pointMachineResponse.isSucess === true) {
            return pointMachineResponse.response;
        } else if (pointMachineResponse.isSucess === false && pointMachineResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return pointMachineResponse.response;
        }
    });
}

// add batteries
export async function getMasterBatteriesAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/batterycharger', value).then((response) => {
        let batteriesResponse = response.data;
        if (batteriesResponse.isSucess === true) {
            return batteriesResponse.response;
        } else if (batteriesResponse.isSucess === false && batteriesResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteriesResponse.response;
        }
    });
}

export async function addBatteriesAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/batterycharger/create', value).then((response) => {
        let batteriesResponse = response.data;
        if (batteriesResponse.isSucess === true) {
            return batteriesResponse.response;
        } else if (batteriesResponse.isSucess === false && batteriesResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteriesResponse.response;
        }
    });
}

export async function editBatteriesAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/batterycharger/update/' + value.id, value).then((response) => {
        let batteriesResponse = response.data;
        if (batteriesResponse.isSucess === true) {
            return batteriesResponse.response;
        } else if (batteriesResponse.isSucess === false && batteriesResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteriesResponse.response;
        }
    });
}

export async function deleteBatteriesAPI(id) {
    const axios = await getAxios();
    return await axios.post('/master/batterycharger/delete/' + id).then((response) => {
        let batteriesResponse = response.data;
        if (batteriesResponse.isSucess === true) {
            return batteriesResponse.response;
        } else if (batteriesResponse.isSucess === false && batteriesResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return batteriesResponse.response;
        }
    });
}

// add ips
export async function getMasterIPSAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/ips', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function addIPSAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/ips/create', value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function editIPSAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/ips/update/' + value.id, value).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

export async function deleteIPSAPI(id) {
    const axios = await getAxios();
    return await axios.post('/master/ips/delete/' + id).then((response) => {
        let ipsResponse = response.data;
        if (ipsResponse.isSucess === true) {
            return ipsResponse.response;
        } else if (ipsResponse.isSucess === false && ipsResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return ipsResponse.response;
        }
    });
}

// add signal
export async function getMasterSignalAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/signal', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function addSignalAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/signal/create', value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function editSignalAPI(value) {
    const axios = await getAxios();
    return await axios.post('/master/signal/update/' + value.id, value).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function deleteSignalAPI(id) {
    const axios = await getAxios();
    return await axios.post('/master/signal/delete/' + id).then((response) => {
        let signalResponse = response.data;
        if (signalResponse.isSucess === true) {
            return signalResponse.response;
        } else if (signalResponse.isSucess === false && signalResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return signalResponse.response;
        }
    });
}

export async function getStationAPI(id) {
    const axios = await getAxios();
    return await axios.post('/dashboard/station/' + id).then((response) => {
        let stationResponse = response.data;
        if (stationResponse.isSucess === true) {
            return stationResponse.response;
        } else if (stationResponse.isSucess === false && stationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return stationResponse.response;
        }
    });
}

// add Station
export async function addStationAPI(value) {
    const axios = await getAxios();
    return await axios.post('/station/create', value).then((response) => {
        let stationResponse = response.data;
        if (stationResponse.isSucess === true) {
            return stationResponse.response;
        } else if (stationResponse.isSucess === false && stationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return stationResponse.response;
        }
    });
}

export async function editStationAPI(value) {
    const axios = await getAxios();
    return await axios.post('/station/update/' + value.id, value).then((response) => {
        let stationResponse = response.data;
        if (stationResponse.isSucess === true) {
            return stationResponse.response;
        } else if (stationResponse.isSucess === false && stationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return stationResponse.response;
        }
    });
}

export async function deleteStationAPI(value) {
    const axios = await getAxios();
    return await axios.post('/station/delete/' + value).then((response) => {
        let stationResponse = response.data;
        if (stationResponse.isSucess === true) {
            return stationResponse.response;
        } else if (stationResponse.isSucess === false && stationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return stationResponse.response;
        }
    });
}

export async function getAllStationAPI(value) {
    const axios = await getAxios();
    return await axios.post('/station', value).then((response) => {
        let stationResponse = response.data;
        if (stationResponse.isSucess === true) {
            return stationResponse.response;
        } else if (stationResponse.isSucess === false && stationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return stationResponse.response;
        }
    });
}

export async function getStationNameAPI(value) {
    const axios = await getAxios();
    return await axios.post('/stations/name', value).then((response) => {
        let stationResponse = response.data;
        if (stationResponse.isSucess === true) {
            return stationResponse.response;
        } else if (stationResponse.isSucess === false && stationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return stationResponse.response;
        }
    });
}

export async function getUserStationAPI(id) {
    const axios = await getAxios();
    return await axios.post('/user/station/' + id).then((response) => {
        let stationResponse = response.data;
        if (stationResponse.isSucess === true) {
            return stationResponse.response;
        } else if (stationResponse.isSucess === false && stationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return stationResponse.response;
        }
    });
}

export async function getReportAPI(value) {
    const axios = await getAxios();
    return await axios.post('/reports', value).then((response) => {
        let reportResponse = response.data;
        if (reportResponse.isSucess === true) {
            return reportResponse.response;
        } else if (reportResponse.isSucess === false && reportResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return reportResponse.response;
        }
    });
}

export async function getDeviceAPI(value) {
    const axios = await getAxios();
    return await axios.post('/device', value).then((response) => {
        let deviceResponse = response.data;
        if (deviceResponse.isSucess === true) {
            return deviceResponse.response;
        } else if (deviceResponse.isSucess === false && deviceResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return deviceResponse.response;
        }
    });
}

export async function getReportGraphAPI(value) {
    const axios = await getAxios();
    return await axios.post('/reports/graph', value).then((response) => {
        let reportResponse = response.data;
        if (reportResponse.isSucess === true) {
            return reportResponse.response;
        } else if (reportResponse.isSucess === false && reportResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return reportResponse.response;
        }
    });
}

// notification

export async function getNotification(value) {
    const axios = await getAxios();
    return await axios.post('/notification', value).then((response) => {
        let notificationResponse = response.data;
        if (notificationResponse.isSucess === true) {
            return notificationResponse.response;
        } else if (notificationResponse.isSucess === false && notificationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return notificationResponse.response;
        }
    });
}

export async function getDeviceDataApi(value) {
    const axios = await getAxios();
    return await axios.post('/notification/all/device', value).then((response) => {
        let notificationResponse = response.data;
        if (notificationResponse.isSucess === true) {
            return notificationResponse.response;
        } else if (notificationResponse.isSucess === false && notificationResponse.error === 'Invalid token') {
            getLogout();
        } else {
            return notificationResponse.response;
        }
    });
}
