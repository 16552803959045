// @flow
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Modal, Button, Badge, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { VerticalForm, FormInput } from '../../../../components/';
// components
import Table from '../../../../components/Table';
import {
    addDCCircuitAPI,
    deleteDCCircuitAPI,
    editDCCircuitAPI,
    getMasterDcCircuitAPI,
    getStationNameAPI,
} from '../../../../API/RDPMS_API';
import moment from 'moment';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from 'react-loading-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

const AddDcCircuit = () => {
    const { t } = useTranslation();

    const [loader, setLoader] = useState(false);

    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [stationName, setStationName] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showEditModalData, setShowEditModalData] = useState([]);

    const { auth } = useSelector((state) => ({
        auth: state.Auth,
    }));

    let stationIds = auth && auth.user && auth.user.stationId && auth.user.stationId;
    let userId = auth && auth.user && auth.user._id && auth.user._id;

    useEffect(() => {
        getMasterDcCircuitData();
        getStationNameData();
    }, []);

    const getStationNameData = async () => {
        Loading();
        let request = {
            userId: userId,
        };
        let response = await getStationNameAPI(request);
        if (response && response.length > 0) {
            setStationName(response);
        }
        Loading();
    };

    const getMasterDcCircuitData = async (limits, pages) => {
        Loading();
        let limitNumber = limits ? limits : limit;
        let pagesNumber = pages ? pages : pageNumber;

        let request = {
            limit: limitNumber,
            page: pagesNumber,
            stationId: stationIds,
        };
        let response = await getMasterDcCircuitAPI(request);
        if (response && response.data && response.data.length > 0) {
            let dataArray = await response.data.map((item) => {
                return {
                    id: item._id,
                    createdDate: moment(item && item.updatedAt && item.updatedAt).format('Do MMM, h:mm a'),
                    trackNumber: item.track_number,
                    channel: item.channel,
                    type: item.type,
                    stationMasterName:
                        item &&
                        item.stationDetails &&
                        item.stationDetails[0] &&
                        item.stationDetails[0].station_master &&
                        item.stationDetails[0].station_master,
                    stationName: item && item.stationDetails && item.stationDetails[0] && item.stationDetails[0].name,
                    city:
                        item &&
                        item.stationDetails &&
                        item.stationDetails[0] &&
                        item.stationDetails[0].city &&
                        item.stationDetails[0].city,
                    state: item && item.stationDetails && item.stationDetails[0] && item.stationDetails[0].state,
                    action: (
                        <>
                            <Link to="#" className="action-icon" onClick={() => openEditModalWithHeaderClass(item)}>
                                {' '}
                                <i className="mdi mdi-pencil"></i>
                            </Link>
                            <Link to="#" className="action-icon" onClick={() => onDeleteDcCircuit(item._id)}>
                                {' '}
                                <i className="mdi mdi-delete"></i>
                            </Link>
                        </>
                    ),
                };
            });

            setPageNumber(response.currentPage);
            setPageCount(response.totalPages);
            setData(dataArray);
            setTotalCount(response.totalCount);
        }
        Loading();
    };

    //pagination
    const handlePageChange = async (value) => {
        setPageNumber(1);
        setLimit(value);
        await getMasterDcCircuitData(value, 1);
    };

    const handlePageClick = async (value) => {
        setPageNumber(value);
        await getMasterDcCircuitData(limit, value);
    };

    const columns = [
        {
            Header: t('Created Date'),
            accessor: 'createdDate',
            sort: true,
        },
        {
            Header: t('Track Number'),
            accessor: 'trackNumber',
            sort: true,
        },
        {
            Header: t('Channel'),
            accessor: 'channel',
            sort: false,
        },
        {
            Header: t('Type'),
            accessor: 'type',
            sort: true,
        },
        {
            Header: t('Station Master Name'),
            accessor: 'stationMasterName',
            sort: false,
        },
        {
            Header: t('Station Name'),
            accessor: 'stationName',
            sort: false,
        },
        {
            Header: t('City'),
            accessor: 'city',
            sort: false,
        },
        {
            Header: t('State'),
            accessor: 'state',
            sort: false,
        },
        {
            Header: t('Action'),
            accessor: 'action',
            sort: false,
        },
    ];
    const sizePerPageList = [
        {
            text: '5',
            value: 5,
        },
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
    ];

    const closeModalWithHeaderClass = () => {
        setShowModal(false);
    };
    const openModalWithHeaderClass = () => {
        setShowModal(true);
    };

    const closeEditModalWithHeaderClass = () => {
        setShowEditModal(false);
    };
    const openEditModalWithHeaderClass = (data) => {
        setShowEditModalData(data);
        setShowEditModal(true);
    };

    /*
     * form validation schema
     */
    const schemaResolver = yupResolver(
        yup.object().shape({
            trackNumber: yup.string().required(t('Please enter track number')),
            stationId: yup.string().required(t('Please select station name')),
            deviceId: yup.string().required(t('Please enter device id')),
            channel: yup.number().required(t('Please enter channel number')),
            type: yup.string().required(t('Please select type')),
        })
    );

    /*
     * handle form submission
     */
    const onSubmit = async (formData) => {
        setLoader(true);
        let result = await addDCCircuitAPI(formData);
        if (result && result.length > 0) {
            getMasterDcCircuitData();
            setShowModal(false);
            toast.success('Added new dc circuit successfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error('Please enter diffrent channel number', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setLoader(false);
    };

    const onEdit = async (formData) => {
        setLoader(true);
        let change = false;
        if (showEditModalData.channel !== formData.channel || showEditModalData.device_id !== formData.deviceId) {
            change = true;
        }

        formData.org = change;
        formData.id = showEditModalData._id;
        let result = await editDCCircuitAPI(formData);

        if (result) {
            getMasterDcCircuitData();
            setShowEditModal(false);
            toast.success('Updated dc circuit successfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error('Please enter diffrent channel number', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setLoader(false);
    };

    const onDeleteDcCircuit = async (id) => {
        Loading();
        if (window.confirm('Are you sure you wish to delete this dc circuit?')) {
            let result = await deleteDCCircuitAPI(id);

            if (result) {
                getMasterDcCircuitData();
                toast.success('Deleted dc circuit successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error('Deleted dc circuit unsuccessfully', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
        Loading();
    };

    return (
        <>
            <ToastContainer />
            <Row>
                <Col>
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <form className="d-flex">
                                <div className="input-group">
                                    <Button variant="primary" onClick={openModalWithHeaderClass}>
                                        ADD
                                    </Button>
                                    {/* <HyperDatepicker
                                        value={selectedDate}
                                        inputClass="form-control-light"
                                        onChange={(date) => {
                                            onDateChange(date);
                                        }}
                                    /> */}
                                </div>
                                <button className="btn btn-primary ms-2">
                                    <i className="mdi mdi-autorenew"></i>
                                </button>
                            </form>
                        </div>
                        <h4 className="page-title">{t('DC Circuit Overview')}</h4>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Table
                                columns={columns}
                                data={data}
                                pageSize={limit}
                                pageCount={pageCount}
                                pageNumber={pageNumber}
                                totalCount={totalCount}
                                sizePerPageList={sizePerPageList}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                                onPageChange={handlePageChange}
                                onPageClick={handlePageClick}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} size="xs" onHide={closeModalWithHeaderClass}>
                <Modal.Header onHide={closeModalWithHeaderClass} closeButton className="modal-colored-header">
                    <h4 className="modal-title text-black">ADD DC CIRCUIT</h4>
                </Modal.Header>

                <Modal.Body>
                    <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
                        <FormInput
                            label={t('Track Number')}
                            type="text"
                            name="trackNumber"
                            placeholder={t('Enter Track Number')}
                            containerClass={'mb-3'}
                        />

                        <FormInput
                            type="select"
                            label="Station Name"
                            name="stationId"
                            containerClass={'mb-3'}
                            key="className">
                            <option value="">Select Station Name</option>
                            {stationName.map((item, index) => {
                                return (
                                    <option key={index} value={item._id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </FormInput>

                        <FormInput
                            label={t('Device ID')}
                            type="text"
                            name="deviceId"
                            placeholder={t('Enter device id')}
                            containerClass={'mb-3'}
                        />
                        <FormInput
                            label={t('Channel Number')}
                            type="text"
                            name="channel"
                            placeholder={t('Enter channel number')}
                            containerClass={'mb-3'}
                        />

                        <FormInput type="select" label="Type" name="type" containerClass={'mb-3'} key="className">
                            <option value="">Select Type</option>
                            <option value="current">Current</option>
                            <option value="voltage">Voltage</option>
                        </FormInput>

                        <Modal.Footer>
                            <Button variant="primary" type="submit" disabled={loader ? true : false}>
                                {loader ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {t(' Loading')}
                                    </>
                                ) : (
                                    <>
                                        <i className="mdi mdi-login"></i>
                                        {t('Submit')}
                                    </>
                                )}
                            </Button>
                        </Modal.Footer>
                    </VerticalForm>
                </Modal.Body>
            </Modal>

            <Modal show={showEditModal} size="xs" onHide={closeEditModalWithHeaderClass}>
                <Modal.Header onHide={closeEditModalWithHeaderClass} closeButton className="modal-colored-header">
                    <h4 className="modal-title text-black">EDIT DC CIRCUIT</h4>
                </Modal.Header>

                <Modal.Body>
                    <VerticalForm onSubmit={onEdit} resolver={schemaResolver}>
                        <FormInput
                            label={t('Track Number')}
                            type="text"
                            name="trackNumber"
                            placeholder={t('Enter Track Number')}
                            containerClass={'mb-3'}
                            defaultValue={showEditModalData.track_number}
                        />

                        <FormInput
                            type="select"
                            label="Station Name"
                            name="stationId"
                            containerClass={'mb-3'}
                            key="className">
                            <option value={showEditModalData.station_id}>
                                {showEditModalData &&
                                    showEditModalData.stationDetails &&
                                    showEditModalData.stationDetails[0] &&
                                    showEditModalData.stationDetails[0].name}
                            </option>
                            {stationName.map((item, index) => {
                                let name =
                                    showEditModalData &&
                                    showEditModalData.stationDetails &&
                                    showEditModalData.stationDetails[0] &&
                                    showEditModalData.stationDetails[0].name;
                                let id = showEditModalData.station_id;

                                if (name !== item.name && id !== item._id) {
                                    return (
                                        <option key={index} value={item._id}>
                                            {item.name}
                                        </option>
                                    );
                                }
                            })}
                        </FormInput>

                        <FormInput
                            label={t('Device ID')}
                            type="text"
                            name="deviceId"
                            placeholder={t('Enter device id')}
                            containerClass={'mb-3'}
                            defaultValue={showEditModalData.device_id}
                        />
                        <FormInput
                            label={t('Channel Number')}
                            type="text"
                            name="channel"
                            placeholder={t('Enter channel number')}
                            containerClass={'mb-3'}
                            defaultValue={showEditModalData.channel}
                        />

                        <FormInput type="select" label="Type" name="type" containerClass={'mb-3'} key="className">
                            <option value={showEditModalData.type}>
                                {showEditModalData.type ? showEditModalData.type : ''}
                            </option>
                            {showEditModalData.type !== 'current' ? <option value="current">Current</option> : ''}
                            {showEditModalData.type !== 'voltage' ? <option value="voltage">Voltage</option> : ''}
                        </FormInput>
                        <Modal.Footer>
                            <Button variant="primary" type="submit" disabled={loader ? true : false}>
                                {loader ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {t(' Loading')}
                                    </>
                                ) : (
                                    <>
                                        <i className="mdi mdi-login"></i>
                                        {t('Submit')}
                                    </>
                                )}
                            </Button>
                        </Modal.Footer>
                    </VerticalForm>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddDcCircuit;
