// @flow
import React from 'react';
import Chart from 'react-apexcharts';

// const RelayStepLineChart = ({ data }) => {
//     const apexLineChartWithLables = {
//         chart: {
//             height: 364,
//             type: 'scatter',
//             dropShadow: {
//                 enabled: true,
//                 opacity: 0.1,
//                 blur: 7,
//                 left: -7,
//                 top: 7,
//             },
//             toolbar: {
//                 show: true,
//             },
//             parentHeightOffset: 0,
//         },
//         grid: {
//             padding: {
//                 left: 10,
//                 right: 10,
//             },
//         },
//         dataLabels: {
//             enabled: false,
//         },
//         // stroke: {
//         //     curve: ['stepline'],
//         //     width: 4,
//         // },
//         zoom: {
//             enabled: true,
//         },
//         legend: {
//             position: 'top',
//             // horizontalAlign: 'right',
//             showForSingleSeries: true,
//             show: true,
//         },
//         colors: ['#0acf97', '#ff0000'],
//         xaxis: {
//             type: 'datetime',
//             labels: {
//                 format: 'dd MMM yyyy',
//             },
//             tooltip: {
//                 enabled: false,
//             },
//             axisBorder: {
//                 show: false,
//             },
//         },
//         yaxis: {
//             show: false,
//             tickAmount: 2,
//             min: 0,
//             max: 2,
//             forceNiceScale: true,
//         },
//     };

//     const apexLineChartWithLablesData = [
//         {
//             name: 'Forward',
//             data: data && data.forward && data.forward.length > 0 ? data.forward : [],
//         },
//         {
//             name: 'Reverse',
//             data: data && data.reverse && data.reverse.length > 0 ? data.reverse : [],
//         },
//     ];

//     return (
//         <Chart
//             options={apexLineChartWithLables}
//             series={apexLineChartWithLablesData}
//             type="scatter"
//             className="apex-charts mt-3"
//             height={378}
//         />
//     );
// };

// export default RelayStepLineChart;

const RelayStepLineChart = ({ data }) => {
    // const data = [
    //     ['2024-06-12T15:47:41.352Z', 0],
    //     ['2024-06-12T16:47:41.352Z', 1],
    //     ['2024-06-13T17:47:41.352Z', 0],
    //     ['2024-06-13T18:47:41.352Z', 1],
    //     ['2024-06-14T15:47:41.352Z', 0],
    //     ['2024-06-14T15:47:41.352Z', 0],
    //     ['2024-06-15T15:47:41.352Z', 1],
    //     ['2024-06-15T15:47:41.352Z', 1],
    //     ['2024-06-16T15:47:41.352Z', 0],
    //     ['2024-06-17T15:47:41.352Z', 0],
    // ];
    console.log(data, 'RelayStepLineChart');
    const apexLineChartWithLables = {
        chart: {
            height: 364,
            type: 'line',
            dropShadow: {
                enabled: true,
                opacity: 0.1,
                blur: 7,
                left: -7,
                top: 7,
            },
            toolbar: {
                show: true,
            },
            parentHeightOffset: 0,
        },
        grid: {
            padding: {
                left: 0,
                right: 0,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: ['stepline'],
            width: 4,
        },
        zoom: {
            enabled: true,
        },
        // legend: {
        //     position: 'top',
        //     horizontalAlign: 'right',
        //     showForSingleSeries: true,
        // show: true,
        // },
        // markers: {
        //     size: [5, 7],
        // },
        colors: ['#979797'],
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'dd MMM yyyy',
            },
            tooltip: {
                enabled: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            type: 'category',
            categories: ['Reverse', 'Forward'],
            tickAmount: 1,
            labels: {
                formatter: function (value) {
                    return value === 0 ? 'Reverse' : 'Forward';
                },
            },
            min: 0,
            max: 1,
            forceNiceScale: true,
        },
    };

    const apexLineChartWithLablesData = [
        {
            name: 'Point Position Relay',
            data: data,
        },
    ];

    return (
        <Chart
            options={apexLineChartWithLables}
            series={apexLineChartWithLablesData}
            type="line"
            className="apex-charts mt-3"
            height={378}
        />
    );
};

export default RelayStepLineChart;
